

.main {
    background-image:url('../assets/mobileBackground.png');
    height:110vh;
    width:100%;
    background-size:cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    object-fit: cover;
    box-sizing: content-box;
    overflow:hidden;

    

}




@media   (orientation: landscape) {
    .main {
      height: 800px;
    }
  }


@media screen and (min-width:1024px) {
    .main {
        background-image: url('../assets/largeBg.svg');
        height:120vh;
        background-repeat: no-repeat;
        background-size: cover;
       
    }
}

@media screen and (min-width:1280px) {
    
    .main {
        background-image: url('../assets/Finalised\ \(1\).svg');
        height:130vh;

        background-size: cover;
       
    }
}


@media screen and (min-width:1900px)  {
    .main {
        height:100vh;
    }
}




.garden-container {
    
    position: absolute;
    bottom: 0;
    left:0;
    right:0;
   
    width: 100%;
    
    display: flex;
    flex-direction: column;
    gap:0;
   
}
.grass-container {
    background-image: url("../assets/mobileGrass2.svg");
    height: 20%;
    width:100%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top:-1px;
    z-index:40;
    position: relative;
}


@media screen and (min-width:520px) {
    .grass-container {
        height:10%;
    }
}

@media screen and (min-width:1024px) {
    .grass-container {
        background-image: url("../assets/largeGrass.svg");
       width:100%;
        height:120px;
    }
     
       
}

@media screen and (min-width:1280px) {
    .grass-container {
        height:130px;
    }
}




@media screen and (min-width:1900px) {
    .grass-container {
        height:190px;
    }
}
@media screen and (min-width:1600px) {
    .grass-container {
        height:190px;
    }
}


@media screen and (min-width:1440px)  {
    .grass-container {
        height:140px;
    }
}




@media screen and (min-width:2560px) {
    .grass-container {
        height:240px;
    }
}